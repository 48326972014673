<template>
  <div>
    <div class="section section__teams">
      <div class="section__wrapper">
        <div class="row mb-4">
          <div class="col">
            <div class="title-with-icon mb-2 mb-sm-0">
              <div class="title-with-icon__icon title-with-icon__icon_partners"></div>
              <div class="title-with-icon__title">Региональные управленческие команды</div>
            </div>
          </div>
          <div class="col-auto">
            <a href="#" class="link-target" @click.prevent="checkAuth()" data-toggle="modal" data-target="#add-question"><span>Задать вопрос</span></a>
          </div>
        </div>

        <ul class="nav nav-tabs mb-5" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <a class="nav-link active" id="basic-tab" data-toggle="tab" href="#basic" role="tab" aria-controls="basic"
               aria-selected="true">Основные сведения</a>
          </li>
<!--          <li class="nav-item" role="presentation">
            <a class="nav-link" id="materials-tab" data-toggle="tab" href="#materials" role="tab"
               aria-controls="materials"
               aria-selected="false">Методические материалы</a>
          </li>-->
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="select-tab" data-toggle="tab" href="#select" role="tab"
               aria-controls="select"
               aria-selected="false">Порядок отбора субъектов РФ</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="online-tab" data-toggle="tab" href="#online" role="tab"
               aria-controls="online"
               aria-selected="false">Организация обучения</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="graduates-tab" data-toggle="tab" href="#graduates" role="tab"
               aria-controls="online"
               aria-selected="false">Выпускники программы</a>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="basic" role="tabpanel" aria-labelledby="basic-tab">
            <h2 class="mb-3">Системные инициативы и нормативная база</h2>
            <div class="content_18 mb-4">
              С 2021 года Школа экспорта РЭЦ реализует образовательную программу для региональных управленческих команд субъектов Российской Федерации* в целях реализации мероприятий Регионального экспортного стандарта 2.0.
            </div>
            <div class="content_18 mb-24">
              Программа нацелена на вовлечение региональных органов исполнительной власти в процесс стратегического планирования и реализации региональной политики в области поддержки и развития экспортной деятельности в субъекте Российской Федерации, а также на формирование системного подхода к реализации Регионального экспортного стандарта 2.0.
            </div>
            <div class="content_18 mb-24">
              Обучение региональных управленческих команд субъектов Российской Федерации для реализации мероприятий Регионального экспортного стандарта 2.0 является мероприятием паспорта Федерального проекта «Системные меры развития международной кооперации и экспорта» национального проекта «Международная кооперация и экспорт».
            </div>
            <div class="content_18 mb-24">
              В целях реализации данного мероприятия Федерального проекта «Системные меры развития международной кооперации и экспорта» постановлением Правительства Российской Федерации от 28.04.2021 № 668 утверждены правила предоставления субсидии из федерального бюджета в целях реализации мероприятий по разработке и реализации образовательных программ, предусматривающих комплексное развитие экспортных компетенций региональных управленческих команд и представителей субъектов малого и среднего предпринимательства.
            </div>
            <h2 class="mb-24">Ссылки на документы:</h2>
            <div class="container-grid grid-3 mb-4">
              <document title="Региональный экспортный стандарт 2.0." link="https://exporteduru.servicecdn.ru/media/default/0002/94/62c0e588e320ee1987217bf380d97afef44f63e8.pdf"></document>
              <document title="Паспорт Федерального проекта «Системные меры развития международной кооперации и экспорта»" link="https://exporteduru.servicecdn.ru/media/default/0002/94/eb5fb21e9839da7452b7337320237108577a3777.pdf"></document>
              <document :title="'Постановление Правительства РФ от 28.04.2021 №668<b>***</b>'" link="/docs/pprf.pdf"></document>
            </div>
            <div class="item-description mb-24">
              *руководители и представители органов исполнительной власти субъектов РФ, органов местного самоуправления, а также представители иных организаций, участвующие в разработке и реализации региональной программы по развитию экспорта субъекта РФ в соответствии с Региональным экспортным стандартом.
            </div>
            <div class="item-description mb-24">
              **комплексный план развития экспорта субъекта Российской Федерации на период до 2024 года с прогнозом до 2030 года, включающий ключевые цели, показатели и мероприятия субъекта Российской Федерации, в которых присутствует экспортная составляющая.
            </div>
            <div class="item-description mb-24">
              ***в редакции Постановлений Правительства РФ от 28.07.2021 N1272, от 19.01.2022 N17.
            </div>
          </div>
<!--          <div class="tab-pane fade" id="materials" role="tabpanel" aria-labelledby="materials-tab">
            <div class="accordion" id="accordion-materials">
              <div class="accordion__item container-shadow">
                <div class="accordion__header" id="heading-1">
                  <h2 data-toggle="collapse" data-target="#collapse-1" aria-expanded="true" aria-controls="collapse-1">
                    Рекомендации по организации самостоятельного обучения управленческих команд
                  </h2>
                </div>
                <div id="collapse-1" class="collapse show accordion__body pb-0" aria-labelledby="heading-1"
                     data-parent="#accordion-materials">
                  <div class="section__course-text">
                    <div class="content_18 mb-4">Региональный экспортный стандарт 2.0. разработан АО «Российский экспортный
                      центр» и утвержден Проектным комитетом по национальному проекту "Международная кооперация и экспорт"
                      (протокол от 30 июля 2020 г. № 6).
                    </div>
                    <div class="notice notice_red mb-4">
                      <div class="notice__content content_700">Каждый субъект Российской Федерации, внедряющий Региональный
                        экспортный стандарт 2.0., самостоятельно принимает решения относительно программ, форматов,
                        продолжительности и периодичности обучения управленческой команды, а также обеспечивает
                        финансирование данного обучения.
                      </div>
                    </div>
                    <div class="content_18 mb-4">
                      Мероприятие № 3 Регионального экспортного стандарта 2.0. предусматривает обучение управленческой
                      команды субъекта Российской Федерации и ее переаттестацию с целью подтверждения компетенций раз в
                      <span class="content_700">2 года</span>.
                    </div>
                    <div class="notice notice_red mb-4">
                      <div class="notice__content content_700">В целях оказания методической поддержки субъектам Российской
                        Федерации подготовлены рекомендации по организации самостоятельного обучения региональных
                        управленческих команд субъектов Российской Федерации, внедряющих Региональной экспортный стандарт
                        2.0.
                      </div>
                    </div>
                    <div class="content_18 mb-4">
                      Рекомендации содержат общие подходы к организации обучения управленческих команд для развития
                      компетенций, необходимых для решения поставленных задач.
                    </div>
                    <div class="content_subtitle mb-24">Ссылки на документы:</div>
                    <div class="container-grid grid-3">
                      <div class="container-grid__item">
                        <div class="doc fill-grey p-24 container-shadow">
                          <div class="doc__content row">
                            <div class="col-auto text-center">
                              <div class="ext-wrapper m-0">
                                <img src="/pic/icon-doc.svg"/>

                              </div>
                            </div>
                            <div class="col">
                              <div class="doc__title">Методические рекомендации Школы экспорта РЭЦ</div>
                            </div>
                          </div>
                          <div class="doc__actions">
                            <a href="/docs/metodicheskie-rekomendacii-shkoly-eksporta-REC.pdf" class="btn btn-outline-primary">Скачать</a>
                          </div>
                        </div>
                      </div>
                      <div class="container-grid__item">
                        <div class="doc fill-grey p-24 container-shadow">
                          <div class="doc__content row">
                            <div class="col-auto text-center">
                              <div class="ext-wrapper m-0">
                                <img src="/pic/icon-doc.svg"/>

                              </div>
                            </div>
                            <div class="col">
                              <div class="doc__title">Презентация для регионов</div>
                            </div>
                          </div>
                          <div class="doc__actions">
                            <a href="/docs/presentation.pdf" class="btn btn-outline-primary">Скачать</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="accordion__item container-shadow">
                <div class="accordion__header" id="heading-2">
                  <h2 class="collapsed" data-toggle="collapse" data-target="#collapse-2" aria-expanded="false"
                      aria-controls="collapse-2">
                    Перечень рекомендованных образовательных программ для самостоятельного изучения
                  </h2>
                </div>
                <div id="collapse-2" class="collapse accordion__body pb-0" aria-labelledby="heading-2"
                     data-parent="#accordion-materials">
                  <div class="content_18 mb-24">
                    Перечень рекомендованных программ сформирован по результатам исследования регионального рынка программ
                    дополнительного профессионального образования в сфере ВЭД (экспортной деятельности), приоритетных
                    отраслей экономики, в сфере проектного управления и пр. Исследование выполнено в феврале-апреле 2021
                    года Институтом торговой политики НИУ «Высшей школы экономики». В итоговый перечень включены программы
                    образовательных организаций, реализуемые в регионах Российской Федерации на момент проведения
                    исследования.
                  </div>
                  <div class="notice notice_red mb-7">
                    <h2 class="notice__title">Данный перечень не является конечным</h2>
                    <div class="notice__content">Каждый регион может принять участие в его дополнении либо актуализации
                      путем направления уточняющей информации.
                    </div>
                  </div>
                  <div class="section__teams-form">
                    <form class="needs-validation" novalidate>
                      <div class="form-item">
                        <input class="form-control" name="" id="fio" placeholder="ФИО" required>
                        <div class="invalid-feedback">
                          Введите ФИО.
                        </div>
                      </div>
                      <div class="form-item">
                        <input class="form-control" name="" id="email" placeholder="Ваш e-mail" required/>
                        <div class="invalid-feedback">
                          Введите email.
                        </div>
                      </div>
                      <div class="form-item">
                        <input class="form-control" name="" id="name-program"
                               placeholder="Наименование программы ДПО и ВУЗа" required/>
                        <div class="invalid-feedback">
                          Введите наименование программы
                        </div>
                      </div>
                      <div class="form-item">
                  <textarea class="form-control" name="" id="name-comment"
                            placeholder="Комментарий (почему рекомендуете программу, обоснование)" required></textarea>
                        <div class="invalid-feedback">
                          Добавьте комментарий
                        </div>
                      </div>
                      <div class="form-item d-flex justify-content-end">
                        <button type="submit" class="link-target"><span>Отправить</span></button>
                      </div>
                    </form>
                  </div>
                  <div class="content_18 mb-5">Дополнительно приведена информация, предоставленная ведущими профильными
                    образовательными организациями по разработанным программам, потенциально соответствующим целям и задачам
                    развития компетенций региональных управленческих команд, которые могут быть реализованы по запросу.
                  </div>
                  <div class="content_subtitle mb-24">Ссылки на документы:</div>
                  <div class="container-grid grid-3">
                    <div class="container-grid__item">
                      <div class="doc fill-grey p-24 container-shadow">
                        <div class="doc__content row">
                          <div class="col-auto text-center">
                            <div class="ext-wrapper m-0">
                              <img src="/pic/icon-doc.svg"/>

                            </div>
                          </div>
                          <div class="col">
                            <div class="doc__title">Перечень рекомендованных программ (май 2021 года)</div>
                          </div>
                        </div>
                        <div class="doc__actions">
                          <a href="/docs/recommend.pdf" class="btn btn-outline-primary">Скачать</a>
                        </div>
                      </div>
                    </div>
                    <div class="container-grid__item">
                      <div class="doc fill-grey p-24 container-shadow">
                        <div class="doc__content row">
                          <div class="col-auto text-center">
                            <div class="ext-wrapper m-0">
                              <img src="/pic/icon-doc.svg"/>

                            </div>
                          </div>
                          <div class="col">
                            <div class="doc__title">Рекомендации НИУ ВШЭ (дополнительные шаги при выборе программ обучения)
                            </div>
                          </div>
                        </div>
                        <div class="doc__actions">
                          <a href="/docs/recommendniuvsh.pdf" class="btn btn-outline-primary">Скачать</a>
                        </div>
                      </div>
                    </div>
                    <div class="container-grid__item">
                      <div class="doc fill-grey p-24 container-shadow">
                        <div class="doc__content row">
                          <div class="col-auto text-center">
                            <div class="ext-wrapper m-0">
                              <img src="/pic/icon-doc.svg"/>

                            </div>
                          </div>
                          <div class="col">
                            <div class="doc__title">Программы по запросу</div>
                          </div>
                        </div>
                        <div class="doc__actions">
                          <a href="/docs/method.pdf" class="btn btn-outline-primary">Скачать</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>-->
          <div class="tab-pane fade" id="online" role="tabpanel" aria-labelledby="online-tab">
            <div class="accordion" id="accordion-online">
              <div class="accordion__item container-shadow">
                <div class="accordion__header" id="online-heading-1">
                  <h2 data-toggle="collapse" data-target="#online-collapse-1" aria-expanded="true"
                      aria-controls="online-collapse-1">
                    Сотрудничество с субъектом Российской Федерации
                  </h2>
                </div>
                <div id="online-collapse-1" class="collapse show accordion__body pb-0" aria-labelledby="online-heading-1"
                     data-parent="#accordion-online">
                  <div class="content_18 mb-4">
                    Взаимодействие между прошедшим отбор субъектом Российской Федерации и Школой экспорта РЭЦ по обучению региональной управленческой команды и по обучению экспортно ориентированных субъектов МСП будет осуществляться на основании следующих документов:
                  </div>
                  <div class="notice notice_red mb-4">
                    <h2 class="notice__title">
                      1. Соглашение о взаимодействии между субъектом Российской Федерации и АНО ДПО «Школа экспорта Акционерного общества «Российский экспортный центр»
                    </h2>
                    <div class="notice__content">
                      <ul class="list list_dotted">
                        <li>
                          Срок действия соглашения - до конца 2024 года
                        </li>
                        <li>
                          Устанавливает основные направления сотрудничества до 2024 года по мероприятиям Регионального экспортного стандарта 2.0. (обучение и аттестация региональной управленческой команды; реализация программы экспортных семинаров для региональных МСП; развитие системы подготовки кадров в сфере внешнеэкономической деятельности на базе высших учебных заведений субъекта Российской Федерации)
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="notice notice_red mb-4">
                    <h2 class="notice__title">
                      2. Меморандум о сотрудничестве по обучению региональной управленческой команды
                    </h2>
                    <div class="notice__content">
                      <ul class="list list_dotted">
                        <li>Срок действия меморандума - до конца 2023 года</li>
                        <li>
                          Устанавливает права и обязанности Сторон в рамках реализации обучения региональной управленческой команды субъекта Российской Федерации
                        </li>
                        <li>
                          Меморандумом утверждается заявка региона по участникам обучения и утверждается форма закрывающего Акта
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="notice notice_red mb-4">
                    <h2 class="notice__title">
                      3. Дополнительное соглашение к Соглашению о реализации программы экспортных семинаров «Жизненный цикл экспортного проекта»
                    </h2>
                    <div class="notice__content">
                      <ul class="list list_dotted">
                        <li>Подписывается региональным органом исполнительной власти (курирующим ВЭД в регионе), АО «Российский экспортный центр», Центром поддержки экспорта и Школой экспорта РЭЦ</li>
                        <li>Срок реализации подпрограммы «Экспортный стандарт для МСП» - до конца 2023 года</li>
                        <li>Устанавливает права и обязанности Сторон в рамках реализации программы экспортных семинаров «Жизненный цикл экспортного проекта»</li>
                        <li>На основании Соглашения (допсоглашения) субъекту передается простая (неисключительная) лицензия на право реализации программы семинаров для МСП на территории региона, на базе регионального центра поддержки экспорта</li>
                      </ul>
                    </div>
                  </div>
                  <div class="container-grid grid-3">
                    <document title="Проект Соглашения о взаимодействии между регионом и Школой экспорта РЭЦ" link="https://exporteduru.servicecdn.ru/media/default/0002/94/d16cbed9b5f0b8b05f849d6eb0303847583f6cd6.doc"></document>
                    <document title="Проект Дополнительного соглашения к соглашению по реализации программы семинаров.doc" link="https://exporteduru.servicecdn.ru/media/default/0002/94/9127404736b3b85ea3b42580b3d4cd5f5d42f107.doc"></document>
                    <document title="Проект Меморандума о сотрудничестве по обучению рег. управ. команд.docx" link="//exporteduru.servicecdn.ru/media/default/0002/94/068f36f8f9d496800a7e26020b833d22f5022854.docx"></document>
                  </div>
                </div>
              </div>
              <div class="accordion__item container-shadow">
                <div class="accordion__header" id="online-heading-2">
                  <h2 class="collapsed" data-toggle="collapse" data-target="#online-collapse-2" aria-expanded="false"
                      aria-controls="collapse-2">
                    Личный кабинет
                  </h2>
                </div>
                <div id="online-collapse-2" class="collapse accordion__body" aria-labelledby="heading-2"
                     data-parent="#accordion-online">
                  <div class="content_18 mb-1">
                    Организационное взаимодействие между субъектом Российской Федерации и Школой экспорта РЭЦ осуществляется через Личный кабинет.
                  </div>
                  <div class="content_18">
                    Регистрация личного кабинета региональной управленческой команды необходима для централизованной обработки документов команды и обеспечения их сохранности, а также приглашения участников команды к обучению
                  </div>
                </div>
              </div>
              <div class="accordion__item container-shadow">
                <div class="accordion__header" id="online-heading-3">
                  <h2 class="collapsed" data-toggle="collapse" data-target="#online-collapse-3" aria-expanded="false"
                      aria-controls="collapse-3">
                    Оценка участников региональных управленческих команд
                  </h2>
                </div>
                <div id="online-collapse-3" class="collapse accordion__body pb-0" aria-labelledby="heading-3"
                     data-parent="#accordion-online">
                  <div class="notice notice_red mb-4">
                    <h2 class="notice__title">
                      Задачи проведения оценки:
                    </h2>
                    <div class="notice__content">
                      <ul class="list list_dotted">
                        <li>
                          Выявление общего уровня теоретических и практических знаний в сфере внешнеэкономической деятельности участников региональных управленческих команд
                        </li>
                        <li>
                          Диагностика профессионально-деловых и личностных качеств, выявление командных ролей каждого участника региональной управленческой команды, ролевой структуры каждой региональной управленческой команды (сильные и слабые стороны, потенциал и зоны развития).
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="content_subtitle content_700 mb-4">Специализированные инструменты оценки</div>
                  <div class="row section__teams-points mb-24">
                    <div class="col-sm-7">
                      <div class="mr-3">
                        <div class="row">
                          <div class="col-6">
                            <img src="/pic/arrow-down-blue.svg" class="mb-4 section__teams-scheme-down"/>
                            <div class="content_subtitle content_700 content_blue mb-5">
                              Тест профессиональных знаний в области внешнеэкономической деятельности (3 степени сложности).
                            </div>
                          </div>
                          <div class="col-6">
                            <img src="/pic/arrow-down-blue.svg" class="mb-4 section__teams-scheme-down"/>
                            <div class="content_subtitle content_700 content_blue mb-5">Тесты на знание принципов проектного
                              управления и стратегического мышления (3 степени сложности).
                            </div>
                          </div>
                        </div>
                        <img src="/pic/arrow-join.svg" class="mb-4 section__teams-scheme-join"/>
                        <div class="content_18 content_blue">Количество, сложность и распределение тематики вопросов
                          варьируются в зависимости от категории участников региональных управленческих команд.
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-5">
                      <img src="/pic/arrow-down-blue.svg" class="mb-4 section__teams-scheme-down"/>
                      <div class="content_subtitle content_700 content_blue mb-5">Профессионально-личностный опросник,
                        направленный на выявление личностных особенностей и поведения, проявляемого в процессе трудовой
                        деятельности.
                      </div>
                    </div>
                  </div>

                  <div class="notice notice_red mb-4">
                    <h2 class="notice__title">Результаты оценки:
                    </h2>
                    <div class="notice__content">
                      <ul class="list list_dotted">
                        <li>Результаты не влияют на допуск или не допуск участника к обучению по программе «Экспорт
                          регионов 2.0.»
                        </li>
                        <li>Результаты используются для подготовки рекомендаций по развитию компетенций, недостаток
                          которых был выявлен в результате оценки
                        </li>
                        <li>Результаты тестов профессиональных знаний будут учтены при формировании рекомендаций для
                          самостоятельного обучения за пределами образовательной программы
                        </li>
                        <li>Результаты оценки личности участников будут учтены для подготовки рекомендаций ВДЛ региона с
                          целью повышения эффективности командной работы
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="mb-4">
                    <div class="content_18 mb-1">
                      Доступ к тестам профессиональных знаний осуществляется через индивидуальные личные кабинеты участников региональной управленческой команды. Ссылки на тесты направляются Школой экспорта РЭЦ индивидуальными письмами каждому участнику региональной управленческой команды
                    </div>
                    <div class="content_18 mb-1">
                      Для прохождения личностной оценки будет автоматически выслана индивидуальная ссылка-доступ к специализированной онлайн-платформе, на которой размещены соответствующие опросники.
                    </div>
                  </div>

                  <div class="content_subtitle mb-24">Ссылки на документы:</div>
                  <div class="container-grid grid-3">
                    <div class="container-grid__item">
                      <div class="doc fill-grey p-24 container-shadow">
                        <div class="doc__content row">
                          <div class="col-auto text-center">
                            <div class="ext-wrapper m-0">
                              <img src="/pic/icon-doc.svg"/>

                            </div>
                          </div>
                          <div class="col">
                            <div class="doc__title">Положение об оценке участников региональных управленческих команд</div>
                          </div>
                        </div>
                        <div class="doc__actions">
                          <a href="/docs/polozhenie.pdf" class="btn btn-outline-primary">Скачать</a>
                        </div>
                      </div>
                    </div>
                    <div class="container-grid__item">
                      <div class="doc fill-grey p-24 container-shadow">
                        <div class="doc__content row">
                          <div class="col-auto text-center">
                            <div class="ext-wrapper m-0">
                              <img src="/pic/icon-doc.svg"/>

                            </div>
                          </div>
                          <div class="col">
                            <div class="doc__title">Инструкция по прохождению оценки
                            </div>
                          </div>
                        </div>
                        <div class="doc__actions">
                          <a href="/docs/instrucia-ocenka.pdf" class="btn btn-outline-primary">Скачать</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion__item container-shadow">
                <div class="accordion__header" id="online-heading-4">
                  <h2 class="collapsed" data-toggle="collapse" data-target="#online-collapse-4" aria-expanded="false"
                      aria-controls="collapse-4">
                    Краткое описание образовательной программы для региональных управленческих команд
                  </h2>
                </div>
                <div id="online-collapse-4" class="collapse accordion__body" aria-labelledby="heading-4"
                     data-parent="#accordion-online">
                  <div class="content_18 mb-3">
                    Программа нацелена на вовлечение региональных органов исполнительной власти в процесс стратегического планирования и реализации региональной политики в области поддержки и развития экспортной деятельности в субъекте Российской Федерации, а также на формирование системного подхода к реализации Регионального экспортного стандарта 2.0.
                  </div>
                  <div class="notice notice_blue mb-4">
                    <h2 class="notice__title">Участники</h2>
                    <div class="notice__content">
                      <div class="mb-1">
                        Представители региональной управленческой команды субъекта Российской Федерации в соответствии со Стандартом 2.0: представители руководства органов власти субъектов Российской Федерации, органов местного самоуправления, а также руководители или представители организаций, участвующих в разработке и мониторинге реализации региональной программы по развитию экспорта субъекта Российской Федерации.
                      </div>
                      <div>
                        Все участники региональных управленческих команд отобранных субъектов Российской Федерации до начала обучения пройдут процедуру комплексной оценки профессионально-деловых компетенций и личностных качеств.
                      </div>
                    </div>
                  </div>
                  <div class="notice notice_blue mb-4">
                    <h2 class="notice__title">Модель реализации</h2>
                    <div class="notice__content">
                      <div class="mb-1">Образовательная программа состоит из <strong class="content_700">5 модулей</strong></div>
                      <div class="mb-1">
                        <strong class="content_700">Формат обучения:</strong> очно-заочный.
                      </div>
                      <div class="mb-1"><strong class="content_700">Место обучения:</strong> на базе Школы экспорта РЭЦ, Москва, Краснопресненская набережная, 12,
                        Центр международной торговли.
                      </div>
                      <div>
                        По результатам обучения выдается документ о прохождении обучения: удостоверение или справка установленного образца.
                      </div>
                    </div>
                  </div>
                  <div class="notice notice_blue">
                    <h2 class="notice__title">Результат обучения</h2>
                    <div class="notice__content">
                      <div class="mb-1">
                        Результатом участия в образовательных программах для региональных управленческих команд субъектов Российской Федерации является проект региональной программы развития экспорта субъекта Российской Федерации – комплексный план развития экспорта субъекта Российской Федерации на период до 2024 года с прогнозом до 2030 года, включающий ключевые цели, показатели и мероприятия субъекта Российской Федерации, в которых присутствует экспортная составляющая.
                      </div>
                      <div>
                        По итогам обучения предполагается защита перед аттестационной комиссией разработанного в процессе обучения проекта региональной программы развития экспорта субъекта Российской Федерации.
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="accordion__item container-shadow">
                <div class="accordion__header" id="online-heading-5">
                  <h2 class="collapsed" data-toggle="collapse" data-target="#online-collapse-5" aria-expanded="false"
                      aria-controls="collapse-5">
                    Обучение по онлайн программе
                  </h2>
                </div>
                <div id="online-collapse-5" class="collapse accordion__body" aria-labelledby="heading-5"
                     data-parent="#accordion-online">
                  <div class="content_18 mb-24">
                    <div class="mb-1">Заинтересованные участники управленческих команд субъектов Российской Федерации в целях развития собственных экспортных компетенций могут также самостоятельно пройти обучение по онлайн-программе Школы экспорта РЭЦ, состоящей из 13 курсов, каждый из которых посвящен отдельному этапу экспортного проекта.
                    </div>
                    <div class="mb-1">
                      11 курсов программы являются обязательными для изучения, 2 курса («Выставочно-ярмарочная деятельность экспортеров», «Подготовка предприятия к участию в специализированных бизнес-миссиях») – факультативные.
                    </div>
                  </div>

                  <div class="content_subtitle content_700 mb-24">
                    Для прохождения обучения по дистанционной программе необходимо:
                  </div>
                  <div class="content_18 mb-24">
                    <ul class="list list_dotted">
                      <li>записаться на программу</li>
                      <li>успешно пройти обучение минимум по 11 обязательным курсам (ознакомится с учебными материалами, выполнить тестирование по каждому курсу и оставить отзыв)</li>
                      <li>
                        написать на адрес info@exportedu.ru запрос на прохождение итоговой аттестации по программе (запрос направляется только после прохождения тестов по 11 обязательным курсам!)
                      </li>
                    </ul>
                  </div>
                  <div class="content_18 mb-24">
                    <div class="mb-1">
                      Итоговая аттестация состоит из итогового теста по ключевым темам, включенным в онлайн программу (108 вопросов), и записи видео-презентации по одной из тем программы, назначенной экспертами Школы экспорта РЭЦ (проводится не реже 1-1,5 раза в месяц в дистанционном формате).
                    </div>
                    <div class="mb-1">
                      При условии прохождения обучения по 11 обязательным курсам в рамках онлайн-программы и успешного прохождения итогового теста (не менее 85% правильных ответов) и сдачи видео- презентации выдается удостоверение установленного образца.
                    </div>
                  </div>

                  <div class="notice notice_red mb-3">
                    <h2 class="notice__title">Внимание!</h2>
                    <div class="notice__content">
                      Данное онлайн-обучение предназначено только для участников региональных управленческих команд! Иные заявки будут отклонены. Для получения удостоверения установленного образца по результатам прохождения обучения по онлайн-программе необходимо при регистрации на обучение предоставить направление, подтверждающее принадлежность к региональной управленческой команде.
                    </div>
                  </div>
                  <div class="ml-3">
                    <a href="/online?token=4jksuebc53&trip=regcommand" class="link-target"><span>Записаться на программу</span></a>
                  </div>
                </div>
              </div>
              <div class="accordion__item container-shadow">
                <div class="accordion__header" id="online-heading-6">
                  <h2 class="collapsed" data-toggle="collapse" data-target="#online-collapse-6" aria-expanded="false"
                      aria-controls="collapse-6">
                    Рекомендации по организации самостоятельного обучения управленческих команд
                  </h2>
                </div>
                <div id="online-collapse-6" class="collapse accordion__body" aria-labelledby="heading-5"
                     data-parent="#accordion-online">
                  <div class="content_18 mb-24">
                    <div class="mb-1">
                      Каждый субъект Российской Федерации самостоятельно принимает решения относительно программ, форматов, продолжительности и периодичности обучения своей управленческой команды, а также обеспечивает финансирование данного обучения.
                    </div>
                    <div class="mb-1 notice notice_red">
                      В целях оказания методической поддержки субъектам Российской Федерации подготовлены рекомендации по организации самостоятельного обучения региональных управленческих команд субъектов Российской Федерации, внедряющих Региональной экспортный стандарт 2.0.
                    </div>
                    <div class="mb-1">
                      По результатам исследования регионального рынка программ дополнительного профессионального образования в сфере ВЭД, приоритетных отраслей экономики, в сфере проектного управления, которое было выполнено Институтом торговой политики НИУ ВШЭ в апреле 2021 года, сформирован перечень рекомендованных программ для самостоятельного обучения, который ежегодно актуализируется.
                    </div>
                  </div>

                  <div class="notice notice_red mb-7">
                    <h2 class="notice__title">Данный перечень не является конечным</h2>
                    <div class="notice__content">Каждый регион может принять участие в его дополнении либо актуализации
                      путем направления уточняющей информации.
                    </div>
                  </div>
                  <div class="section__teams-form">
                    <form class="needs-validation" novalidate>
                      <div class="form-item">
                        <input class="form-control" name="" id="fio" placeholder="ФИО" required>
                        <div class="invalid-feedback">
                          Введите ФИО.
                        </div>
                      </div>
                      <div class="form-item">
                        <input class="form-control" name="" id="email" placeholder="Ваш e-mail" required/>
                        <div class="invalid-feedback">
                          Введите email.
                        </div>
                      </div>
                      <div class="form-item">
                        <input class="form-control" name="" id="name-program"
                               placeholder="Наименование программы ДПО и ВУЗа" required/>
                        <div class="invalid-feedback">
                          Введите наименование программы
                        </div>
                      </div>
                      <div class="form-item">
                  <textarea class="form-control" name="" id="name-comment"
                            placeholder="Комментарий (почему рекомендуете программу, обоснование)" required></textarea>
                        <div class="invalid-feedback">
                          Добавьте комментарий
                        </div>
                      </div>
                      <div class="form-item d-flex justify-content-end">
                        <button type="submit" class="link-target"><span>Отправить</span></button>
                      </div>
                    </form>
                  </div>
                  <div class="content_subtitle mb-24">Ссылки на документы:</div>
                  <div class="container-grid grid-3">
                    <document title="Презентация для регионов" link="/docs/presentation.pdf"/>
                    <document title="Методические рекомендации Школы экспорта РЭЦ" link="https://exporteduru.servicecdn.ru/media/default/0002/95/4ec9c36dada3619d0e5c0833118113f52ebdeb79.pdf"/>
                    <document title="Рекомендации НИУ ВШЭ (дополнительные шаги при выборе программ обучения)" link="/docs/recommendniuvsh.pdf"/>
                  </div>
                  <div class="container-grid grid-3">
                    <document title="Перечень рекомендованных программ" link="https://exporteduru.servicecdn.ru/media/default/0002/95/f3836482571fbd79e7a0bb4a233078c8cc59d9d4.pdf" />
                    <document title="«Компетенции для реализации экспортного потенциала региона» (Российский внешнеэкономический вестник)" link="https://exporteduru.servicecdn.ru/media/default/0002/66/18ac929c598fb96b8421819d1d8d89554a8856d4.pdf"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="select" role="tabpanel" aria-labelledby="select-tab">
            <div class="accordion" id="accordion-select">
              <div class="accordion__item container-shadow">
                <div class="accordion__header" id="select-heading-2">
                  <h2 class="collapsed" data-toggle="collapse" data-target="#select-collapse-2" aria-expanded="false"
                      aria-controls="collapse-2">
                    Заявка на участие в отборе
                  </h2>
                </div>
                <div id="select-collapse-2" class="collapse show accordion__body pb-0" aria-labelledby="select-2" data-parent="#accordion-select">
                  <div class="content_18 mb-1">
                    Согласно требованиям Постановления Правительства Российской Федерации от 28.04.2021 №668 предусмотрен открытый отбор субъектов РФ для участия в образовательных программах Школы экспорта РЭЦ, предусматривающих комплексное развитие экспортных компетенций региональных управленческих команд и представителей субъектов малого и среднего предпринимательства.
                  </div>
                  <div class="content_18 mb-1">
                    Отбор будет проводиться на основании Порядка отбора субъектов Российский Федерации для участия в образовательной программе для региональных управленческих команд и реализации на территории субъектов Российской Федерации программ экспортных семинаров для представителей субъектов малого и среднего предпринимательства, утвержденного Приказом АО «Российский экспортный центр» от 30.06.2021 №99/РЭЦ.
                  </div>
                  <div class="content_18 mb-3">
                    Прием и обработку поступивших заявок и документов осуществляет Школа экспорта РЭЦ.
                  </div>
                  <div class="content_18 mb-3">
                    Для участия в отборе необходимо предоставить в официальном порядке заявку (с приложениями) по установленной форме, подписанную высшим должностным лицом субъекта РФ (руководителем высшего исполнительного органа государственной власти субъекта РФ) или уполномоченным им лицом.
                  </div>
                  <div class="content_18 mb-3">
                    <i>Документы, поступившие после окончания срока, не рассматриваются. Датой приема заявки считается дата регистрации поступивших входящих документов.</i>
                  </div>

                  <div class="content_subtitle content_700 mb-24">Перечень документов:</div>
                  <div class="content_18 mb-24">
                    <ul class="list list_dotted">
                      <li>Заявка по утвержденной форме</li>
                      <li>Заполненная анкета субъекта РФ по утвержденной форме</li>
                      <li>Копия документа, утверждающего список участников региональной управленческой команды субъекта РФ</li>
                    </ul>
                  </div>
                  <div class="content_18 mb-4">
                    <div class="mb-1">
                      Официальная заявка должна быть подписана собственноручно либо с использованием усиленной квалифицированной электронной подписи в соответствии с законодательством Российской Федерации об использовании электронных подписей.
                    </div>
                    <div>
                      <p>Официальная заявка с приложением документов может быть направлена следующими способами (только одним способом, выбор которого осуществляется субъектом РФ самостоятельно):</p>
                      <p>1) подписанная усиленной квалифицированной электронной подписью направляется: с использованием системы межведомственного электронного документооборота (МЭДО) или с использованием электронной почты на адрес: info@exportcenter.ru</p>
                      <p>2) подписанная на бумажном носителе направляется по адресу места проведения отбора: 123610, Москва, Краснопресненская наб., д. 12, подъезд 9, Школа экспорта РЭЦ</p>
                    </div>
                  </div>
                  <div class="notice notice_red mb-24">
                    <h2 class="notice__title">Внимание! </h2>
                    <div class="notice_content">
                      Срок подачи документов: до 10 марта 2023 г. (включительно).
                    </div>
                  </div>
                  <div class="item-description mb-4">
                    Документы, поступившие после окончания срока, не рассматриваются. Датой приема заявки считается дата регистрации поступивших входящих документов.
                  </div>
                  <div class="container-grid grid-2">
                    <div class="container-grid__item">
                      <div class="notice notice_blue">
                        <h2 class="notice__title">Участники отбора*:</h2>
                        <div class="notice_content">
                          к участию в отборе в текущем году допускаются субъекты Российской Федерации, реализующие инструменты Регионального экспортного стандарта 2.0. в 2023 году, а также субъекты Российской Федерации, не прошедшие обучение по образовательной программе для региональных управленческих команд в 2021-2022 годах.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item-description mb-4">
                    *Всего в текущем 2023 году с учетом выделенного объема бюджетных ассигнований будет отобрано 15 субъектов РФ.
                  </div>
                  <div class="content_subtitle content_700 mb-24">Ссылки на документы:</div>
                  <div class="container-grid grid-1">
                    <div class="container-grid__item">
                      <div class="doc fill-white p-24 container-shadow">
                        <div class="doc__content row">
                          <div class="col-auto text-center">
                            <div class="ext-wrapper m-0">
                              <img src="/pic/icon-doc.svg">

                            </div>
                          </div>
                          <div class="col">
                            <div class="doc__title">Порядок отбора субъектов Российский Федерации для участия в
                              образовательной программе для региональных управленческих команд и реализации на территории
                              субъектов Российской Федерации программ экспортных семинаров для представителей субъектов
                              малого и среднего предпринимательства
                            </div>
                          </div>
                        </div>
                        <div class="doc__actions">
                          <a target="_blank" download="Порядок отбора субъектов РФ.pdf" href="https://exporteduru.servicecdn.ru/media/default/0003/23/6d455e4abeb46d06a88f85ee08bb549da97d7e2c.pdf" class="btn btn-outline-primary">Скачать</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="container-grid grid-2">
                    <div class="container-grid__item">
                      <div class="doc fill-white p-24 container-shadow">
                        <div class="doc__content row">
                          <div class="col-auto text-center">
                            <div class="ext-wrapper m-0">
                              <img src="/pic/icon-doc.svg">

                            </div>
                          </div>
                          <div class="col">
                            <div class="doc__title">Форма заявки на участие в отборе
                            </div>
                          </div>
                        </div>
                        <div class="doc__actions">
                          <a href="/docs/order-form.docx" class="btn btn-outline-primary">Скачать</a>
                        </div>
                      </div>
                    </div>
                    <div class="container-grid__item">
                      <div class="doc fill-white p-24 container-shadow">
                        <div class="doc__content row">
                          <div class="col-auto text-center">
                            <div class="ext-wrapper m-0">
                              <img src="/pic/icon-doc.svg">

                            </div>
                          </div>
                          <div class="col">
                            <div class="doc__title">Форма анкеты субъекта РФ</div>
                          </div>
                        </div>
                        <div class="doc__actions">
                          <a href="/docs/form-questionnaire.docx" class="btn btn-outline-primary">Скачать</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion__item container-shadow">
                <div class="accordion__header" id="select-heading-3">
                  <h2 class="collapsed" data-toggle="collapse" data-target="#select-collapse-3" aria-expanded="false"
                      aria-controls="collapse-3">
                    Информационная поддержка
                  </h2>
                </div>
                <div id="select-collapse-3" class="collapse accordion__body" aria-labelledby="heading-3"
                     data-parent="#accordion-select">

                  <div class="content_18 mb-5">
                    Школа экспорта РЭЦ оказывает информационно-консультационную поддержку по вопросам участия в отборе и подготовки необходимого пакета документов.
                  </div>

                  <div class="section__teams-form mt-4 mb-5">
                    <div class="content_subtitle content_700 mb-5">Задать вопрос</div>
                    <form @submit.prevent="sendMail" class="needs-validation" novalidate>
                      <div class="form-item">
                        <input class="form-control" v-model="sendForm.fio" id="fio-2" placeholder="ФИО" required>
                        <div class="invalid-feedback">
                          Введите ФИО.
                        </div>
                      </div>
                      <div class="form-item">
                        <input class="form-control" v-model="sendForm.email" id="email-2" placeholder="Ваш e-mail" required/>
                        <div class="invalid-feedback">
                          Введите email.
                        </div>
                      </div>
                      <div class="form-item">
                        <input class="form-control" v-model="sendForm.phone" id="phone"
                               placeholder="Мобильный телефон" required/>
                        <div class="invalid-feedback">
                          Введите номер телефона
                        </div>
                      </div>
                      <div class="form-item">
                        <textarea class="form-control" v-model="sendForm.question" id="question-2"
                                  placeholder="Задать вопрос" required></textarea>
                        <div class="invalid-feedback">
                          Добавьте Ваш вопрос
                        </div>
                      </div>
                      <div class="form-item d-flex justify-content-end">
                        <button type="submit" class="link-target"><span>Отправить</span></button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="accordion__item container-shadow">
                <div class="accordion__header" id="select-heading-1">
                  <h2 data-toggle="collapse" data-target="#select-collapse-1" aria-expanded="true"
                      aria-controls="select-collapse-1">
                    Результаты отбора
                  </h2>
                </div>
                <div id="select-collapse-1" class="collapse accordion__body" aria-labelledby="select-heading-1"
                     data-parent="#accordion-select">
                  <div class="content_18 mb-4">
                    <div class="content_subtitle content_700 mb-24">Перечень субъектов Российской Федерации для участия в 2023 году в образовательной программе для региональных управленческих команд (перечень в алфавитном порядке)</div>
                    <ol>
                      <li>Амурская область</li>
                      <li>Воронежская область</li>
                      <li>Кемеровская область</li>
                      <li>Курганская область</li>
                      <li>Мурманская область</li>
                      <li>Новгородская область</li>
                      <li>Пермский край</li>
                      <li>Республика Карелия</li>
                      <li>Республика Коми</li>
                      <li>Республика Крым</li>
                      <li>Тамбовская область</li>
                      <li>Томская область</li>
                      <li>Тульская область</li>
                      <li>Хабаровский край</li>
                      <li>Чувашская Республика</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="graduates" role="tabpanel" aria-labelledby="select-tab">
            <div class="accordion" id="accordion-select-region">
              <div class="accordion__item container-shadow">
                <div class="accordion__header" id="select-heading-region-2">
                  <h2 class="collapsed" data-toggle="collapse" data-target="#select-collapse-region-2" aria-expanded="false"
                      aria-controls="collapse-region-2">
                    Регионы-участники обучения 2021 года
                  </h2>
                </div>
                <div id="select-collapse-region-2" class="collapse show accordion__body pb-5" aria-labelledby="select-region-2" data-parent="#accordion-select-region">
                  <ul class="list content_18">
                    <li v-for="(region, key) in detRegions21()" :key="key">
                      {{ key+1 }}. {{ region }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="accordion__item container-shadow">
                <div class="accordion__header" id="select-heading-region-1">
                  <h2 class="collapsed" data-toggle="collapse" data-target="#select-collapse-region-1" aria-expanded="false"
                      aria-controls="collapse-region-1">
                    Регионы-участники обучения 2022 года
                  </h2>
                </div>
                <div id="select-collapse-region-1" class="collapse accordion__body pb-5" aria-labelledby="select-region-1" data-parent="#accordion-select-region">
                  <ul class="list content_18">
                    <li v-for="(region, key) in detRegions22()" :key="key">
                      {{ key+1 }}. {{ region }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="accordion__item container-shadow">
                <div class="accordion__header" id="select-heading-region-23">
                  <h2 class="collapsed" data-toggle="collapse" data-target="#select-collapse-region-23" aria-expanded="false"
                      aria-controls="collapse-region-23">
                    Регионы-участники обучения 2023 года
                  </h2>
                </div>
                <div id="select-collapse-region-23" class="collapse accordion__body pb-5" aria-labelledby="select-region-23" data-parent="#accordion-select-region">
                  <ul class="list content_18">
                    <li v-for="(region, key) in detRegions23()" :key="key">
                      {{ key+1 }}. {{ region }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="accordion__item container-shadow">
                <div class="accordion__header" id="select-heading-region-3">
                  <h2 class="collapsed" data-toggle="collapse" data-target="#select-collapse-region-3" aria-expanded="false"
                      aria-controls="collapse-region-3">
                    Лига выпускников
                  </h2>
                </div>
                <div id="select-collapse-region-3" class="collapse accordion__body pb-5" aria-labelledby="select-region-3" data-parent="#accordion-select-region">
                  <div class="content_18">
                    <p><b>Лига выпускников</b> – это объединение лучших выпускников – членов региональных управленческих команд, прошедших обучение в Школе экспорта РЭЦ по программе «Экспорте регионов 2.0» и вошедших в топ-3 по итогам комплексной аттестации.</p>
                    <div class="notice notice_blue">
                      <div class="notice_content">
                        <b>Целью</b> Лиги выпускников является создание условий для дальнейшего развития профессиональных компетенций выпускников программы и формирование профессионального сообщества единомышленников, заинтересованных в развитии экспорта в своем регионе.
                      </div>
                    </div>
                  </div>
                  <div class="content_subtitle content_700 mb-24 mt-5">
                    Задачи Лиги
                  </div>
                  <div class="mb-4 content_18">
                    <ul class="list list_dotted">
                      <li>Популяризация экспортной деятельности;</li>
                      <li>Создание форматов и площадок для развития и укрепления делового межрегионального взаимодействия для обсуждения системных проблем, совместного поиска и апробации новых идей и решений, выработки совместных предложений и рекомендаций, реализации совместных проектов;</li>
                      <li>Создание условий для расширения сотрудничества участников Лиги выпускников с федеральными институтами и инфраструктурой поддержки экспорта в регионах, экспертным и бизнес-сообществом;</li>
                      <li>Формирование экспертной позиции по ключевым вопросам развития экспорта регионов для дальнейшей системной проработки на федеральном уровне при поддержке Группы РЭЦ;</li>
                      <li>Формирование базы квалифицированных кадров в сфере экспортной деятельности в субъектах Российской Федерации;</li>
                      <li>Развитие профессиональных знаний и навыков, лидерских качеств и управленческих компетенций участников Лиги выпускников.</li>
                    </ul>
                  </div>
                  <div class="notice notice_red content_18 mb-5">
                    <div class="notice_content">
                      Состав Лиги выпускников формируется персональными приглашениями Генерального директора Школы экспорта РЭЦ в адрес региональных управленческих команд – участников обучения разных лет, вошедших в топ-3 лучших своего потока по результатам обучения и комплексной аттестации.
                    </div>
                  </div>
                  <div class="container-grid grid-3">
                    <document title="Устав Лиги выпускников" link="//exporteduru.servicecdn.ru/media/default/0002/89/fd2529e8a672aec7d4478fd72be1eed2b60c7b76.pdf" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <page-footer />
  </div>
</template>

<script>
import PageFooter from "../components/PageFooter";
import ApiService from "../../services/api.service";
import {TokenService} from "../../services/token.service";
import Document from "@/components/Document.vue";
import _ from 'lodash';
export default {
  name: "Teams",
  components: {Document, PageFooter},
  data() {
    return {
      sendForm: {}
    }
  },
  mounted() {
    this.$parent.init()
  },
  methods: {
    checkAuth() {
      if(!TokenService.getUser()) {
        window.location.href = '/sign-in'
      }
    },
    sendMail() {
      ApiService.post('region-mails', {
        sendFrom: "info@exportcenter.ru",
        fullName: this.sendForm.fio,
        email: this.sendForm.email,
        phone: this.sendForm.phone,
        comment: this.sendForm.text
      }).then(() => {
        this.sendForm = {}
      })
    },
    detRegions21() {
      let regions = [
        'Нижегородская область',
        'Краснодарский край',
        'Ленинградская область',
        'Республика Башкортостан',
        'Челябинская область',
        'Новосибирская область',
        'Приморский край',
        'Смоленская область',
        'Алтайский край',
        'Рязанская область',
        'Омская область',
        'Волгоградская область',
        'Липецкая область',
        'Пензенская область',
        'Удмуртская Республика'
      ];
      return _.sortBy(regions)
    },
    detRegions22() {
      let regions = [
        'Владимирская область',
        'Иркутская область',
        'Калужская область',
        'Кировская область',
        'Курская область',
        'Оренбургская область',
        'Орловская область',
        'Республика Бурятия',
        'Республика Татарстан (Татарстан)',
        'Ростовская область',
        'Саратовская область',
        'Ставропольский край',
        'Тверская область',
        'Ульяновская область',
        'Ханты-Мансийский автономный округ - Югра'
      ];
      return _.sortBy(regions)
    },
    detRegions23() {
      let regions = [
        'Амурская область',
        'Воронежская область',
        'Кемеровская область',
        'Курганская область',
        'Мурманская область',
        'Новгородская область',
        'Пермский край',
        'Республика Карелия',
        'Республика Коми',
        'Республика Крым',
        'Тамбовская область',
        'Томская область',
        'Тульская область',
        'Хабаровский край',
        'Чувашская Республика',
      ];
      return _.sortBy(regions)
    }
  }
}
</script>

<style scoped>

</style>